@import "~stylesheets/frontend/variables/colors";

.exam-list {
  .back-button {
    float: right;
    cursor: pointer;
    font-size: 16px;
    vertical-align: middle;
  }

  .card {
    background-color: $color-white;
    box-shadow: 0 0 15px 0 rgba(3, 3, 3, 0.1);
    color: $color-default;
    padding: (66px - 18px)/2;
    margin-bottom: 23px;

    .title {
      font-size: 18px;
      margin-bottom: 15px;
    }

    &.exams {
      .exam {
        display: flex;
        flex-direction: row;
        align-items: center;

        .exam-reply-icon {
          flex-basis: 30px;
          margin-left: 15px;
          margin-right: 5px;
        }

        .exam-box {
          margin-top: 20px;
          padding: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;
        }
      }
    }

    .exam-actions {
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      height: 44px;
    }

    .exam-action {
      display: flex;
      align-items: center;
      outline: none;
      cursor: pointer;
      padding: 5px;
      font-size: 25px;
      margin-left: 10px;

      &:not(:hover) {
        background-color: $color-white;
      }
    }

    .exam-button {
      display: inline-block;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
      vertical-align: middle;

      .frontend-icon {
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}
